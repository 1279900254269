<template>
  <v-data-iterator
    :items="items"
    :search="search"
    :loading="loading"
    :loading-text="'Chargement en cours...'"
    item-key="_id"
  >
    <template v-slot:default="props">
      <v-container fluid>
        <v-row>
          <v-col
            v-for="item in props.items"
            :key="item.name"
            cols="12"
            sm="6"
          >
            <v-card>
              <v-toolbar :color="getItemClass(item)" dense flat>
                <v-spacer />
                <v-toolbar-title class="headline">
                  {{ item.serialNumber }}
                </v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-divider />
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>{{ item.partType.name }}</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ item.serialNumber }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>Référence :</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ item.partNumber.name }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>Ordre de Fabrication :</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ item.batch.number }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="item.finish">
                  <v-list-item-content>Fabriqué le :</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ new Date(item.finishAt).toLocaleString() }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="item.finish">
                  <v-list-item-content>Nombre d'utilisation :</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ item.useCount }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-else>
                  <v-list-item-content>Pièce en attente à l'opération :</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ item.currentOperationType.name }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider />
              <v-toolbar color="#e6e6e6" dense flat>
                <v-spacer />
                <v-toolbar-title class="headline">
                  Traçabilité
                </v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-divider />
              <v-expansion-panels>
                <v-expansion-panel
                  v-for="(part, index) in item.sortTraceability"
                  :key="index"
                  inset
                >
                  <v-expansion-panel-header>
                    {{ part.partType.name }} : {{ part.serialNumber }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card>
                      <v-divider />
                      <v-card-text>
                        <v-list dense>
                          <v-list-item>
                            <v-list-item-content>Référence :</v-list-item-content>
                            <v-list-item-content class="align-end">
                              {{ part.partNumber.name }}
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-content>Ordre de Fabrication :</v-list-item-content>
                            <v-list-item-content class="align-end">
                              {{ part.batch.number }}
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="part.finish">
                            <v-list-item-content>Fabriqué le :</v-list-item-content>
                            <v-list-item-content class="align-end">
                              {{ new Date(part.finishAt).toLocaleString() }}
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="part.finish">
                            <v-list-item-content>Nombre d'utilisation :</v-list-item-content>
                            <v-list-item-content class="align-end">
                              {{ part.useCount }}
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-data-iterator>
</template>

<script>
export default {
  name: 'PartList',
  components: {},
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    search: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    items () {
      return this.value.map(part => {
        const tempObject = {}
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < part.traceability.length; index++) {
          tempObject[part.traceability[index]._id.toString()] = part.traceability[index]
        }
        part.traceability = []
        for (const key in tempObject) {
          part.traceability.push(tempObject[key])
        }
        return {
          ...part,
          // eslint-disable-next-line max-len
          sortTraceability: part.traceability.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)),
        }
      })
    },
  },
  methods: {
    getItemClass (item) {
      if (item.destroyed) return '#b7b7b7'
      if (item.rejected) return '#ffaeae'
      if (item.retouched) return '#aed4ff'
      if (item.exception) return '#fff7ae'
      return ''
    },
  },
}
</script>
