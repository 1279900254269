<template>
  <ag-card-container
    title="Traçabilité ascendante"
    fluid
    no-content-padding
  >
    <v-container fluid class="py-0">
      <filter-selection :loading="loadingData" @validate="fetchData" />
    </v-container>
    <ag-card-container
      v-if="resultPartList.length"
      :search.sync="search"
      title="Résultats"
      fluid
      no-content-padding
    >
      <part-list
        :value="resultPartList"
        :loading="loadingData"
        :search="search"
      />
    </ag-card-container>
  </ag-card-container>
</template>

<script>
import AgCardContainer from 'src/components/common/AgCardContainer'
import { mapActions } from 'vuex'
import Repositories from 'src/repositories'
import FilterSelection from '../components/FilterSelection'
import PartList from '../components/PartList'

export default {
  name: 'TraceabilityAscContainer',
  components: {
    AgCardContainer,
    FilterSelection,
    PartList,
  },
  data: () => ({
    resultPartList: [],
    loadingData: false,
    search: '',
  }),
  methods: {
    ...mapActions('ui', {
      showErrorNotification: 'showErrorNotification',
    }),
    async fetchData (partList) {
      try {
        this.loadingData = true
        this.resultPartList = await Repositories.part.getListTraceabilityAsc(
          {
            _id: partList,
          },
        )
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingData = false
      }
    },
  },
}
</script>
