<template>
  <v-row class="my-0" justify="center" align="center">
    <v-col cols="auto">
      <part-list-selection v-model="partList" @remove="removePart" />
    </v-col>
    <v-col cols="auto">
      <v-btn
        color="accent"
        :loading="loading"
        :disabled="!canValidate"
        @click="$emit('validate', partList)"
      >
        Valider
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import PartListSelection from './PartListSelection'

export default {
  name: 'FilterSelection',
  components: { PartListSelection },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    partList: [],
  }),
  computed: {
    canValidate () {
      if (this.partList.length) return true
      return false
    },
  },
  methods: {
    removePart (item) {
      const index = this.partList.indexOf(item._id)
      if (index >= 0) this.partList.splice(index, 1)
    },
  },
}
</script>
